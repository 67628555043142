import { createStore } from "redux";
import { globalReducer } from "./EnergyReducer";

// const rootReducer = combineReducers({
//   physical: physicalReducer,
//   spiritual: spiritualReducer,
//   inputField: inputReducer,
// });

const store = createStore(globalReducer);

export default store;
