import { GLOBAL_ENERGY } from "./EnergyType";

const initialState = {
  physical: {
    challenges: {
      total: 0,
      first: 0,
      second: 0,
    },
    talents: {
      total: 0,
      first: 0,
      second: 0,
    },
    goals: {
      total: 0,
      first: 0,
      second: 0,
    },
  },
  spiritual: {
    challenges: {
      total: 0,
      first: 0,
      second: 0,
    },
    talents: {
      total: 0,
      first: 0,
      second: 0,
    },
    goals: {
      total: 0,
      first: 0,
      second: 0,
    },
  },
  totalFirst: 0,
  totalSecond: 0,
  dominant: {
    exist: false,
    value: [],
  },
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_ENERGY:
      const t = action.data;
      let diffValue = ["challenges", "talents", "goals"];
      let smap = new Map();
      let pmap = new Map();
      let tab = [];

      //tableau utiliser pour determiner le dominan vibratoir
      let allVal = [];
      let toF = 0;
      let toS = 0;

      diffValue.forEach((item) => {
        switch (item) {
          case "challenges":
            tab = t["spiCha"];
            break;
          case "talents":
            tab = t["spiTal"];
            break;
          case "goals":
            tab = t["spiGoal"];
            break;
          default:
            break;
        }

        let total = tab.reduce((a, b) => a + b, 0);
        // let total = 45;
        let first = total;
        while (first > 22) {
          const n = first
            .toString()
            .split("")
            .map((num) => parseInt(num, 10));
          first = n.reduce((a, b) => a + b, 0);
        }
        let second = first;
        while (second >= 10) {
          const n = second
            .toString()
            .split("")
            .map((num) => parseInt(num, 10));
          second = n.reduce((a, b) => a + b, 0);
        }
        toF += first;
        toS += second;
        smap.set(item, { total, first, second });
        first !== 0 && allVal.push(first);
        second !== 0 && allVal.push(second);
      });
      diffValue.forEach((item) => {
        switch (item) {
          case "challenges":
            tab = t["phyCha"];
            break;
          case "talents":
            tab = t["phyTal"];
            break;
          case "goals":
            tab = t["phyGoal"];
            break;
          default:
            break;
        }

        let total = tab.reduce((a, b) => a + b, 0);
        // let total = 45;
        let first = total;
        while (first > 22) {
          const n = first
            .toString()
            .split("")
            .map((num) => parseInt(num, 10));
          first = n.reduce((a, b) => a + b, 0);
        }
        let second = first;
        while (second >= 10) {
          const n = second
            .toString()
            .split("")
            .map((num) => parseInt(num, 10));
          second = n.reduce((a, b) => a + b, 0);
        }

        toF += first;
        toS += second;

        pmap.set(item, { total, first, second });
        first !== 0 && allVal.push(first);
        second !== 0 && allVal.push(second);
      });

      while (toF >= 22) {
        const n = toF
          .toString()
          .split("")
          .map((num) => parseInt(num, 10));
        toF = n.reduce((a, b) => a + b, 0);
      }

      while (toS >= 10) {
        const n = toS
          .toString()
          .split("")
          .map((num) => parseInt(num, 10));
        toS = n.reduce((a, b) => a + b, 0);
      }
      allVal.push(toF);
      allVal.push(toS);

      let valDominants = [];
      allVal.forEach((v) => {
        let count = 0;
        allVal.forEach((x) => {
          if (v === x) {
            count++;
          }
        });
        if (count >= 4) {
          valDominants.push(v);
          const index = allVal.indexOf(v);
          if (index > -1) {
            allVal.splice(index, 1);
          }
        }
      });
      const sData = Object.fromEntries(smap);
      const pData = Object.fromEntries(pmap);

      let { challenges: sChallenges, talents: sTalents, goals: sGoals } = sData;
      let { challenges: pChallenges, talents: pTalents, goals: pGoals } = pData;
      return {
        ...state,
        spiritual: {
          challenges: sChallenges,
          talents: sTalents,
          goals: sGoals,
        },
        physical: {
          challenges: pChallenges,
          talents: pTalents,
          goals: pGoals,
        },
        totalFirst: toF,
        totalSecond: toS,
        dominant: {
          exist: valDominants.length > 0,
          value: valDominants,
        },
      };

    default:
      return state;
  }
};
export { globalReducer };
