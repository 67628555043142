const data = {
    A:1,
    B:2,
    C:11,
    D:4,
    E:5,
    F:17,
    G:3,
    H:5,
    I:10,
    J:10,
    K:19,
    L:12,
    M:13,
    N:14,
    O:6,
    P:17,
    Q:19,
    R:20,
    S:15,
    T:9,
    U:6,
    V:6,
    W:6,
    X:15,
    Y:16,
    Z:7,
    
}
export default data;