import React, { useState } from "react";
import { calGlobalEnergy } from "./redux/EnergyAction";
// import energy from "./assets/imgs/energy.png";
import data from "./data/Letter";
import bgWitchSoul from "./assets/bgWitchSoul.png";
import bgWitchoutSoul from "./assets/bgWitchoutSoul.png";
import bgSoul from "./assets/bgSoul.png";
import bgPhyChal from "./assets/bgPhyChal.png";
import bgPhyTal from "./assets/bgPhyTal.png";
import bgPhyGoal from "./assets/bgPhyGoal.png";
import bgSpiChal from "./assets/bgSpiChal.png";
import bgSpiTal from "./assets/bgSpiTal.png";
import bgSpiGoal from "./assets/bgSpiGoal.png";
import { connect } from "react-redux";

function App({ state, calGlobalEnergy, inputField }) {
  // let first =
  //   state["spiritual"]["totalFirst"] + state["physical"]["totalFirst"];
  // let second =
  //   state["spiritual"]["totalSecond"] + state["physical"]["totalSecond"];

  // console.log(state);
  const [result, setResult] = useState(false);
  const [disable, setDisable] = useState(false);

  const [name, setName] = useState("");
  const [validName, setValidName] = useState("");

  const [isShort, setIsShort] = useState(false);

  let [nameFormat, setNameFormat] = useState("");
  let [mapNameValue, setMapNameValue] = useState("");

  let [detailPhyCha, setDetailPhyCha] = useState("");
  let [detailPhyTal, setDetailPhyTal] = useState("");
  let [detailPhyGoal, setDetailPhyGoal] = useState("");

  let [detailSpiCha, setDetailSpiCha] = useState("");
  let [detailSpiTal, setDetailSpiTal] = useState("");
  let [detailSpiGoal, setDetailSpiGoal] = useState("");

  let tabLet = [];
  let tabVal = [];

  let phyCha = [];
  let spiCha = [];
  let phyTal = [];
  let spiTal = [];
  let phyGoal = [];
  let spiGoal = [];

  const handleChange = (e) => {
    setNameFormat("");
    setMapNameValue("");
    setDetailPhyCha("");
    setDetailPhyTal("");
    setDetailPhyGoal("");
    setDetailSpiCha("");
    setDetailSpiTal("");
    setDetailSpiGoal("");

    setResult(false);
    setDisable(false);
    if (e.target.value.match("^[a-zA-Z ]*$") != null) {
      setName(e.target.value);
    }
  };

  const calcul = () => {
    if (name !== "" && name.length >= 3) {
      let nameClear = "";
      nameClear = name.replace(/\s/g, "");

      const maxChar = nameClear.length;

      for (let i = 0; i < nameClear.length; i++) {
        const ctransform = nameClear[i].toUpperCase();

        let nextCharTransform = "";
        if (i + 1 < nameClear.length) {
          nextCharTransform = nameClear[i + 1].toUpperCase();
        }

        if (
          i === nameClear.length - 1 &&
          (ctransform === "M" || ctransform === "P")
        ) {
          tabLet.push(ctransform);
          tabVal.push(12);
          setNameFormat(
            (nameFormat +=
              ctransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              12 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
        } else if (ctransform === "A" && nextCharTransform === "H") {
          tabLet.push(ctransform + nextCharTransform);
          tabVal.push(5);
          setNameFormat(
            (nameFormat +=
              ctransform +
              nextCharTransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              5 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          i++;
        } else if (ctransform === "C" && nextCharTransform === "H") {
          tabLet.push(ctransform + nextCharTransform);
          tabVal.push(8);
          setNameFormat(
            (nameFormat +=
              ctransform +
              nextCharTransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              8 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          i++;
        } else if (
          ctransform === "T" &&
          (nextCharTransform === "A" || nextCharTransform === "H")
        ) {
          tabLet.push(ctransform + nextCharTransform);
          tabVal.push(22);
          setNameFormat(
            (nameFormat +=
              ctransform +
              nextCharTransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              22 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          i++;
        } else if (ctransform === "S" && nextCharTransform === "H") {
          tabLet.push(ctransform + nextCharTransform);
          tabVal.push(21);
          setNameFormat(
            (nameFormat +=
              ctransform +
              nextCharTransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              21 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          i++;
        } else if (ctransform === "T" && nextCharTransform === "Z") {
          tabLet.push(ctransform + nextCharTransform);
          tabVal.push(18);
          setNameFormat(
            (nameFormat +=
              ctransform +
              nextCharTransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              18 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          i++;
        } else if (ctransform === "W" && nextCharTransform === "H") {
          tabLet.push(ctransform + nextCharTransform);
          tabVal.push(16);
          setNameFormat(
            (nameFormat +=
              ctransform +
              nextCharTransform +
              (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue +=
              16 + (i === maxChar - 1 || i + 1 === maxChar - 1 ? "" : "-"))
          );
          i++;
        } else {
          tabLet.push(ctransform);
          tabVal.push(data[ctransform]);
          setNameFormat(
            (nameFormat += ctransform + (i === maxChar - 1 ? "" : "-"))
          );
          setMapNameValue(
            (mapNameValue += data[ctransform] + (i === maxChar - 1 ? "" : "-"))
          );
        }
      }
      if (tabVal.length >= 10) {
        setIsShort(false);
        for (let i = 0; i < tabVal.length; i++) {
          phyCha.push(tabVal[i]);
          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }

          spiCha.push(tabVal[i]);
          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }
          phyTal.push(tabVal[i]);

          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }
          spiTal.push(tabVal[i]);
          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }
          phyGoal.push(tabVal[i]);
          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }
          spiGoal.push(tabVal[i]);
        }
      } else {
        setIsShort(true);
        for (let i = 0; i < tabVal.length; i++) {
          phyCha.push(tabVal[i]);
          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }
          phyTal.push(tabVal[i]);
          if (i < tabVal.length - 1) {
            i++;
          } else {
            break;
          }
          phyGoal.push(tabVal[i]);
        }
      }
      setValidName(name);
    } else {
      alert("Name too short");
    }
  };

  const handleClick = () => {
    calcul();
    let globalData = {
      phyCha,
      phyTal,
      phyGoal,
      spiCha,
      spiTal,
      spiGoal,
    };

    phyCha.map((a, i) => {
      return setDetailPhyCha(
        (detailPhyCha += a + (i === phyCha.length - 1 ? "" : "+"))
      );
    });
    phyTal.map((a, i) => {
      return setDetailPhyTal(
        (detailPhyTal += a + (i === phyTal.length - 1 ? "" : "+"))
      );
    });
    phyGoal.map((a, i) => {
      return setDetailPhyGoal(
        (detailPhyGoal += a + (i === phyGoal.length - 1 ? "" : "+"))
      );
    });

    spiCha.map((a, i) => {
      return setDetailSpiCha(
        (detailSpiCha += a + (i === spiCha.length - 1 ? "" : "+"))
      );
    });
    spiTal.map((a, i) => {
      return setDetailSpiTal(
        (detailSpiTal += a + (i === spiTal.length - 1 ? "" : "+"))
      );
    });
    spiGoal.map((a, i) => {
      return setDetailSpiGoal(
        (detailSpiGoal += a + (i === spiGoal.length - 1 ? "" : "+"))
      );
    });

    calGlobalEnergy(globalData);

    if (name !== "" && name.length >= 3) {
      setResult(true);
    }
    setDisable(true);
  };

  return (
    <div className="w-2/4 flex flex-col items-center mt-6">
      <p className="font-thin leading-6 text-md">
      Veuillez entrer votre nom de naissance exactement tel qu'il est orthographié sur votre acte de naissance dans le champ ci-dessous. Veuillez noter que pour les noms avec des caractères spéciaux comme les trémas ou les accents, entrez simplement le nom tel qu'il est orthographié sans ces caractères.
      </p>
      <div className="mt-2 flex  h-10">
        <input
          onChange={handleChange}
          value={name}
          className="h-full text-center outline-none rounded-md pl-2 border-b-2 border-gray-300 focus:border-gray-400 rounder"
          type="text"
          placeholder="Entrez le nom"
        />
        <button
          disabled={disable}
          onClick={handleClick}
          className="h-full font-medium shadow-md ml-2 w-10 bg-gray-300 rounded-md text-gray-500"
        >
          GO
        </button>
      </div>
      {result && (
        <>
          <div className="mt-2 mb-4 text-gray-800 flex flex-col items-center justify-center">
            <p className="text-xl font-bold">{validName.toUpperCase()}</p>
            <p className="text-md font-medium">{nameFormat}</p>
            <p className="text-md font-medium">{mapNameValue}</p>
            {state["dominant"]["exist"] === true && (
              <p className="text-md font-medium">
                Dominant vibration:&nbsp;
                {state["dominant"]["value"].map((v, i) => {
                  return (
                    v + (i < state["dominant"]["value"].length - 1 ? "," : "")
                  );
                })}
              </p>
            )}
          </div>
          <div className="w-2/2 flex justify-center relative mt-2">
            <img
              src={isShort === false ? bgWitchSoul : bgWitchoutSoul}
              alt="bg witch soul"
              width="800"
            />
            {isShort === false ? (
              <>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 top-64">
                  <p className="font-bold prose prose-2xl text-shadow-lg text-black">Soul destiny</p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgSoul} alt="soul" />
                    <p className=" prose prose-2xl font-extrabold text-shadow-lg text-white absolute">
                      {state["totalFirst"] + "-" + state["totalSecond"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 top-28 right-4">
                  <p className="font-bold prose text-shadow-sm text-black prose-xl">
                    Physical Challenges
                  </p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailPhyCha}={state["physical"]["challenges"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgPhyChal} alt="physical challenge" />
                    <p className="font-extrabold prose-xl text-shadow-lg text-black absolute">
                      {state["physical"]["challenges"]["first"]}-
                      {state["physical"]["challenges"]["second"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 bottom-24 right-4">
                  <p className="font-bold text-shadow-sm text-black prose-xl">
                    Spiritual Challenges
                  </p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailSpiCha}={state["spiritual"]["challenges"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgSpiChal} alt="Spiritual challenge" />
                    <p className="font-extrabold prose-xl text-shadow-lg text-black absolute">
                      {state["spiritual"]["challenges"]["first"]}-
                      {state["spiritual"]["challenges"]["second"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 bottom-0">
                  <p className="font-bold text-shadow-sm text-black prose-xl">
                    Physical Talents
                  </p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailPhyTal}={state["physical"]["talents"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgPhyTal} alt="Physical talents" />
                    <p className="font-extrabold prose-xl text-shadow-lg text-black absolute">
                      {state["physical"]["talents"]["first"]}-
                      {state["physical"]["talents"]["second"]}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 top-2">
                  <p className="font-bold text-shadow-sm text-black prose-xl">Spiritual Goal</p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailSpiGoal}={state["spiritual"]["goals"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgSpiGoal} alt="physical challenge" />
                    <p className="font-extrabold prose-xl text-shadow-lg text-black absolute">
                      {state["spiritual"]["goals"]["first"]}-
                      {state["spiritual"]["goals"]["second"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 top-28 left-4">
                  <p className="font-bold text-shadow-sm text-black prose-xl">Physical Goal</p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailPhyGoal}={state["physical"]["goals"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgPhyGoal} alt="Spiritual challenge" />
                    <p className="font-extrabold prose-xl text-shadow-lg text-black absolute">
                      {state["physical"]["goals"]["first"]}-
                      {state["physical"]["goals"]["second"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 bottom-24 left-8">
                  <p className="font-bold text-shadow-sm text-black prose-xl">
                    Spiritual Talents
                  </p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailSpiTal}={state["spiritual"]["talents"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgSpiTal} alt="Spiritual talents" />
                    <p className="font-extrabold prose-xl text-shadow-lg text-black absolute">
                      {state["spiritual"]["talents"]["first"]}-
                      {state["spiritual"]["talents"]["second"]}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 top-2">
                  <p className="font-bold text-shadow-sm text-black prose-xl">Goals</p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailPhyGoal}={state["physical"]["goals"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgSoul} alt="soul" />
                    <p className="font-extrabold text-xl text-shadow-lg text-black absolute">
                      {state["physical"]["goals"]["first"]}-
                      {state["physical"]["goals"]["second"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 bottom-20 left-10">
                  <p className="font-bold text-shadow-sm text-black prose-xl">Talents</p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailPhyTal}={state["physical"]["talents"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgSpiTal} alt="Physical talents" />
                    <p className="font-extrabold text-xl text-shadow-lg text-black absolute">
                      {state["physical"]["talents"]["first"]}-
                      {state["physical"]["talents"]["second"]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center absolute transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110 bottom-20 right-10">
                  <p className="font-bold text-shadow-sm text-black prose-xl">Challenges</p>
                  <p className="font-extrabold text-shadow-sm text-black prose-xl">
                    {detailPhyCha}={state["physical"]["challenges"]["total"]}
                  </p>
                  <div className="relative items-center justify-center flex">
                    <img src={bgPhyTal} alt="Physical talents" />
                    <p className="font-extrabold text-xl text-shadow-lg text-black absolute">
                      {state["physical"]["challenges"]["first"]}-
                      {state["physical"]["challenges"]["second"]}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    calGlobalEnergy: (globalData) => dispatch(calGlobalEnergy(globalData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
